function getImageUrls() {
    const imageNodeList = document.querySelectorAll('.js-image');
    window.IMAGE_COUNT = imageNodeList.length;
    imageNodeList.forEach((imgNode) => {
        window.imageStatementIds.push(imgNode.dataset.statementid);
        window.imageUrls.push(imgNode.getAttribute('src'));
    });
}

function setCanvasDimensions() {
    window.canvas.width = document.getElementById('timeline-container').offsetWidth - 50;
    // 50 is gap between rows and 75 is final breathing space in the end
    window.canvas.height = window.IMAGE_COUNT * (window.IMAGE_HEIGHT + 50) + 75;
    window.COLUMN_WIDTH = window.canvas.width / window.COLUMN_COUNT;
}

function drawVerticalGray() {
    // draw grey vertical lines for columns
    window.ctx.lineWidth = 3;
    window.ctx.strokeStyle = '#eee';
    for (let i = 1; i < window.COLUMN_COUNT; i++) {
        window.ctx.beginPath();
        window.ctx.moveTo(i * window.COLUMN_WIDTH, 0);
        window.ctx.lineTo(i * window.COLUMN_WIDTH, window.canvas.height);
        window.ctx.stroke();
    }
}

function drawHorizontalYellow() {
    const initialYOffset = 125;
    const yOffsetIncrement = 150;

    // draw yellow horizontal lines within the rows
    window.ctx.lineWidth = 1;
    window.ctx.strokeStyle = '#d6de7c';
    for (let i = 0; i < window.IMAGE_COUNT; i++) {
        const yOffset = initialYOffset + i * yOffsetIncrement;
        window.ctx.beginPath();
        // start line from +100px to leave margin on the left
        window.ctx.moveTo(100, yOffset);
        window.ctx.lineTo(canvas.width, yOffset);
        window.ctx.stroke();
    }
}

// draw three different zones/stages on canvas
function drawZones() {
    window.ctx.fillStyle = '#80808080';
    window.ctx.font = '20px sans-serif';
    ['E A R L Y', 'M I D D L E', 'L A T E'].forEach((zone, index) => {
        window.ctx.fillText(zone, index * window.COLUMN_WIDTH + 50, 30);
    });
}

// load symptom images into canvas
function drawImages() {
    // wait for all images to be loaded before drawing canvas
    const imageNodeList = document.querySelectorAll('.js-image');
    const imageCount = imageNodeList.length;
    while (true) {
        let loadCounter = 0;
        for (let i = 0; i < imageCount; i++) {
            if (imageNodeList[i].complete) {
                loadCounter++;
            }
        }
        if (loadCounter === imageCount) {
            break;
        }
    }

    let yOffset = 75;
    window.imageUrls.forEach((imgSrc) => {
        const xOffset = 10;
        const img = new Image();
        img.src = imgSrc;
        window.ctx.drawImage(img, xOffset, yOffset, window.IMAGE_WIDTH, window.IMAGE_HEIGHT);

        // keep track of all images we add along with their coordinates
        window.images.push({
            img: img,
            x: xOffset,
            y: yOffset,
        });

        // increment y offset for next image that will go below
        yOffset += 150;
    });
}

// get mouse position on the x-y axes of canvas
// transforming from the x-y axes of the whole viewport
function getMousePos(evt) {
    const rect = window.canvas.getBoundingClientRect();
    const mousePos = {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top,
    };
    return mousePos;
}

function isMouseOnImage(mousePos, image, evt) {
    /* prettier-ignore */
    return mousePos.x > image.x &&
           mousePos.x < image.x + window.IMAGE_WIDTH &&
           mousePos.y > image.y &&
           mousePos.y < image.y + window.IMAGE_HEIGHT;
}

function setupListeners() {
    // check if mouse is on an image
    window.canvas.addEventListener('mousedown', (evt) => {
        window.images.forEach((imgObj, index) => {
            const mousePos = getMousePos(evt);
            if (isMouseOnImage(mousePos, imgObj, evt)) {
                window.draggingIndex = index;
                window.canvas.style.cursor = 'grab';
            }
        });
    });

    // update position of image as mouse moves
    window.canvas.addEventListener('mousemove', (evt) => {
        // do nothing if draggingIndex is not set
        if (window.draggingIndex === -1) {
            return;
        }

        window.mousePos = getMousePos(evt);

        // draw white on dragged image's previous position
        window.ctx.fillStyle = 'white';
        window.ctx.clearRect(
            // 50 to account for keeping it the middle of the img under the pointer
            window.images[window.draggingIndex].x - 50,
            window.images[window.draggingIndex].y,
            window.IMAGE_WIDTH + 50,
            window.IMAGE_HEIGHT,
        );

        drawVerticalGray();
        drawHorizontalYellow();

        // redraw image in new position
        window.images[window.draggingIndex].x = mousePos.x - 50;
        /* prettier-ignore */
        window.ctx.drawImage(
            window.images[window.draggingIndex].img,
            window.images[window.draggingIndex].x,
            window.images[window.draggingIndex].y,
            window.IMAGE_WIDTH,
            window.IMAGE_HEIGHT,
        );
    });

    window.canvas.addEventListener('mouseup', (evt) => {
        // save position
        const positionPercentage = Math.floor((window.images[window.draggingIndex].x / window.canvas.width) * 100);
        console.log('positionPercentage:', positionPercentage);
        saveImagePosition(positionPercentage);

        // set isDragging to false
        window.draggingIndex = -1;
        window.canvas.style.cursor = 'auto';
    });
}

function saveImagePosition(position) {
    const statementId = window.imageStatementIds[window.draggingIndex];
    console.log('statementId:', statementId);

    fetch(`/api/statement/${statementId}/position/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ position: position }),
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error('Error in saving symptom location:', error);
        });
}

function setImagePositions() {
    fetch(`/api/statement/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            data['statement_list'].forEach((statement, index) => {
                if (statement.position !== 0) {
                    // erase and redraw image if statement has non-zero position
                    window.ctx.fillStyle = 'white';
                    /* prettier-ignore */
                    window.ctx.clearRect(
                        window.images[index].x - 50,
                        window.images[index].y,
                        window.canvas.width,
                        window.IMAGE_HEIGHT,
                    );

                    drawVerticalGray();
                    drawHorizontalYellow();

                    // redraw image in new position
                    window.images[index].x = Math.floor((statement.position * window.canvas.width) / 100);
                    /* prettier-ignore */
                    window.ctx.drawImage(
                        window.images[index].img,
                        window.images[index].x,
                        window.images[index].y,
                        window.IMAGE_WIDTH,
                        window.IMAGE_HEIGHT,
                    );
                }
            });
        })
        .catch((error) => {
            console.error('Error in saving symptom location:', error);
        });
}

function initialise() {
    console.log('hi from timeline');

    window.IMAGE_WIDTH = 100;
    window.IMAGE_HEIGHT = 100;
    window.IMAGE_COUNT = 0;
    window.COLUMN_COUNT = 3;
    window.COLUMN_WIDTH = 0;

    window.canvas = document.getElementById('timeline-canvas');
    window.ctx = window.canvas.getContext('2d');

    window.imageStatementIds = [];
    window.imageUrls = [];
    window.images = [];

    // index of window.images of image currently being dragged
    window.draggingIndex = -1;

    getImageUrls();
    setCanvasDimensions();
    drawZones();
    drawHorizontalYellow();
    drawVerticalGray();
    drawImages();
    setupListeners();
    setImagePositions();
}

document.addEventListener('DOMContentLoaded', function () {
    if (document.location.pathname.indexOf('timeline') > -1) {
        initialise();
        window.addEventListener('resize', function () {
            initialise();
        });
    }
});
