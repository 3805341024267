import htmx from 'htmx.org';
import Alpine from 'alpinejs';
import './main.css';
import './timeline.js';

// expose htmx
window.htmx = htmx;

// attach Alpine to window for capturing events and to use dev toolbar
window.Alpine = Alpine;

window.addEventListener('DOMContentLoaded', () => {
    window.Alpine.store('activeIndex', {
        index: 0,
        set(newindex) {
            this.index = newindex;
        },
    })

    window.Alpine.start();
});

console.log('hi from js');
